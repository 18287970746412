var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-header',{staticClass:"pb-6"},[_c('div',{staticClass:"row align-items-center py-4"},[_c('div',{staticClass:"col-lg-6 col-7"},[_c('h6',{staticClass:"h2 text-white d-inline-block mb-0"})])])]),_c('div',{staticClass:"container-fluid mt--6 header-body"},[_c('div',{staticClass:"card mb-4"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3"},[_c('h3',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.tt('task_tickets')))])]),_c('div',{staticClass:"col-5"},[_c('base-input',{attrs:{"input-classes":"form-control-sm text-center border-radius-20","placeholder":_vm.tt('search')},on:{"keyup":_vm.filter},model:{value:(_vm.table.search),callback:function ($$v) {_vm.$set(_vm.table, "search", $$v)},expression:"table.search"}})],1),_c('div',{staticClass:"col-4 text-right"},[_c('router-link',{attrs:{"to":{ name: 'My Task Show', params: { id: this.params.id }}}},[_c('button',{staticClass:"btn btn-white btn-sm mr-2",attrs:{"type":"button"}},[_vm._v(_vm._s(_vm.tt('waiting')))])]),_c('router-link',{attrs:{"to":{ name: 'My Task Closed', params: { id: this.params.id }}}},[_c('button',{staticClass:"btn btn-default btn-sm",attrs:{"type":"button"}},[_vm._v(_vm._s(_vm.tt('closed')))])])],1)])]),_c('div',[(!_vm.onLoad.table)?_c('el-table',{ref:"multipleTable",staticClass:"table-responsive table-flush",staticStyle:{"width":"100%"},attrs:{"data":_vm.table.data,"header-row-class-name":"thead-light","highlight-current-row":""}},[_c('el-table-column',{attrs:{"label":_vm.tt('description'),"prop":_vm.tt('description'),"min-width":"200px","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.description)+" ")]}}],null,false,3649544633)}),_c('el-table-column',{attrs:{"label":_vm.tt('count_days'),"prop":_vm.tt('count_days'),"min-width":"200px","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.moment(row.assigned_date).locale('id').fromNow())+" ")]}}],null,false,1562199168)}),_c('el-table-column',{attrs:{"label":_vm.tt('assigned_date'),"prop":_vm.tt('assigned_date'),"min-width":"200px","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.format_date(row.assigned_date))+" ")]}}],null,false,1904110685)}),_c('el-table-column',{attrs:{"label":_vm.tt('due_date'),"prop":_vm.tt('due_date'),"min-width":"200px","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.format_date(row.due_date))+" ")]}}],null,false,498930729)}),_c('el-table-column',{attrs:{"prop":"action","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-dropdown',{staticClass:"dropdown",attrs:{"trigger":"click","hide-on-click":true},on:{"command":_vm.handleTableAction}},[_c('span',{staticClass:"btn btn-sm btn-icon-only text-light"},[_c('i',{staticClass:"fas fa-ellipsis-v mt-2"})]),_c('el-dropdown-menu',{staticClass:"dropdown-menu dropdown-menu-arrow show",attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"command":{action: row.view_target,url: row.view_url}}},[_vm._v(_vm._s(_vm.tt("view")))])],1)],1)]}}],null,false,750337757)})],1):_c('page-loading'),_c('div',{staticClass:"card-footer pb-0 "},[_c('span',{staticClass:"float-left"},[_c('base-pagination',{attrs:{"page-count":_vm.table.page.last_page,"size":"sm"},nativeOn:{"click":function($event){return _vm.changePage(_vm.table.page.current_page)}},model:{value:(_vm.table.page.current_page),callback:function ($$v) {_vm.$set(_vm.table.page, "current_page", $$v)},expression:"table.page.current_page"}})],1),_c('span',{staticClass:"float-right"},[_vm._v(" "+_vm._s(_vm.tt('page_info', {size: (_vm.table.page.current_page - 1) * _vm.table.page.per_page + _vm.table.data.length, total: _vm.table.total}))+" ")])])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }