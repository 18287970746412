<template>
    <div>
        <base-header class="pb-6">
            <div class="row align-items-center py-4">
                <div class="col-lg-6 col-7">
                    <h6 class="h2 text-white d-inline-block mb-0"></h6>
                </div>
            </div>
        </base-header>
        <div class="container-fluid mt--6 header-body">
            <div class="card mb-4">
                <div class="card-header">
                    <div class="row">
                        <div class="col-3">
                            <h3 class="mb-0">{{ tt('task_tickets') }}</h3>
                        </div>
                        <div class="col-5">
                            <base-input input-classes="form-control-sm text-center border-radius-20" v-model="table.search" :placeholder="tt('search')" v-on:keyup="filter"/>
                        </div>
                        <div class="col-4 text-right">
                            <router-link :to="{ name: 'My Task Show', params: { id: this.params.id }}">
                                <button type="button" class="btn btn-white btn-sm mr-2">{{ tt('waiting') }}</button>
                            </router-link>
                            <router-link :to="{ name: 'My Task Closed', params: { id: this.params.id }}">
                                <button type="button" class="btn btn-default btn-sm">{{ tt('closed') }}</button>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div>
                    <el-table ref="multipleTable" :data="table.data" header-row-class-name="thead-light" class="table-responsive table-flush" style="width: 100%" v-if="!onLoad.table" highlight-current-row>
                        <el-table-column :label="tt('description')" :prop="tt('description')" min-width="200px" sortable>
                            <template v-slot="{row}">
                                {{row.description}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('count_days')" :prop="tt('count_days')" min-width="200px" sortable>
                            <template v-slot="{row}">
                                {{moment(row.assigned_date).locale('id').fromNow()}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('assigned_date')" :prop="tt('assigned_date')" min-width="200px" sortable>
                            <template v-slot="{row}">
                                {{format_date(row.assigned_date)}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('due_date')" :prop="tt('due_date')" min-width="200px" sortable>
                            <template v-slot="{row}">
                                {{format_date(row.due_date)}}
                            </template>
                        </el-table-column>
                        <el-table-column prop="action" width="100">
                            <template v-slot="{row}">
                                <el-dropdown trigger="click" class="dropdown" :hide-on-click="true" @command="handleTableAction">
                                    <span class="btn btn-sm btn-icon-only text-light">
                                        <i class="fas fa-ellipsis-v mt-2"></i>
                                    </span>
                                    <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                                        <el-dropdown-item :command="{action: row.view_target,url: row.view_url}">{{ tt("view") }}</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </template>
                        </el-table-column>
                    </el-table>
                    <page-loading v-else/>
                    <div class="card-footer pb-0 ">   
                        <span class="float-left">
                            <base-pagination :page-count="table.page.last_page" v-model="table.page.current_page" @click.native="changePage(table.page.current_page)" size="sm"></base-pagination>
                        </span>                             
                        <span class="float-right">
                            {{ tt('page_info', {size: (table.page.current_page - 1) * table.page.per_page + table.data.length, total: table.total}) }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
	import {mapState} from "vuex";
	import Api from '@/helpers/api';
	import myTaskDashboard from '@/services/dashboard/myTaskDashboard.service';
    var moment = require('moment');

	export default {
		data() {
			return {
                moment:moment,
				params: this.$route.params,
				table: {
					search: '',
                    total: 0,
					data: [],
                    page: {
                        current_page: 1,
                        per_page: 100,
                    },
				},
				onLoad: {
					table: true
				}
			}
		},
		computed: {
			...mapState({
				authUserPermission :state => state.auth.userPermission
			}),
		},
		mounted() {
			this.get();
		},
		methods: {
			filter() {
                this.onLoad = true;
                this.table.page.current_page = 1;
                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    this.get()
                }, 100);
            },
			get(param = '') {
				let context = this;
				Api(context, myTaskDashboard.closed(context.params.id, this.table.page.current_page, {search: this.table.search})).onSuccess(function(response) {
                    context.table.total = response.data.data.data.total;
					context.table.data = response.data.data.data.data;
                    context.table.page  = response.data.data.data;
					context.onLoad.table = false;
				}).onError(function(error) {
					context.table.data = [];
					context.onLoad.table = false;
				}).call()
			},
            changePage(page) {
                let context = this;
                context.onLoad = true;

                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    this.get()
                }, 100);
            },
            format_date(value){
                if (value) {
                   return moment(String(value)).locale('id').format('LLL')
                }
            },
            handleTableAction(command) {
                if (command.action == 'confirmation') {

                } else if (command.action == 'self') {
                    this.$router.push(command.url)
                } else if (command.action == 'blank') {
                    
                }
            },
		}
	};
</script>
